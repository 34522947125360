import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import PlayersList from '../../../components/PlayersList/PlayersList';
import './AdminVerJugadores.css';
import { auth, firestore } from '../../../firebase/firebase';
import { getDoc, doc } from 'firebase/firestore';
import axios from 'axios';
import useAuthToken from '../../../hooks/useAuthToken';

const AdminVerJugadores = () => {
  const [jugadores, setJugadores] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { clubName } = location.state || {};
  const { token } = useAuthToken();

   // Determinar la URL base de la API según el entorno
   const apiUrl = process.env.NODE_ENV === 'production' 
   ? process.env.REACT_APP_API_URL_PROD 
     : process.env.REACT_APP_API_URL_PROD;

  useEffect(() => {
    const fetchJugadores = async () => {
      try {
        const clubResponse = await axios.get(`${apiUrl}/clubes/nombre/${clubName}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const clubId = clubResponse.data.club_id;

        const allJugadoresResponse = await axios.get(`${apiUrl}/jugadores/delClub/${clubId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const allJugadores = allJugadoresResponse.data;

        const filteredJugadores = allJugadores.filter(jugador => (jugador.club_id === clubId) && jugador.estado === 'Inscrito');
        setJugadores(filteredJugadores);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    };
    if (token) {
    fetchJugadores();
    }
  }, [token]);

  const handleBackClick = () => {
    navigate('/admin-ver-clubes');
  };

  const handleViewFicha = () => {
    navigate('/admin-ver-ficha');
  };

  return (
    <div className="AdminAsignarRol">
      <Header />
      <Field text="Jugadores" />
      <TitleContainer text={`Jugadores de ${clubName}`} onBackClick={handleBackClick} />
      <PlayersList jugadores={jugadores} handleViewFicha={handleViewFicha} />
    </div>
  );
};

export default AdminVerJugadores;