import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebase/firebase'; // Asegúrate de que la ruta de importación sea correcta
import Header from '../../../components/Header/Header';
import dropDownIcon from '../../../assets/icons/dropdown.png';
import './RepPrincipal.css';

const RepPrincipal = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirige al usuario a la página de inicio de sesión
    } catch (error) {
      console.error('Error al cerrar sesión: ', error);
    }
  };

  return (
    <div className="RepPrincipal">
      <Header />
      <div className="top-bar">
        <nav className="menu">
          <div className="menu-item">
            <h3>Jugadores <img src={dropDownIcon} alt="drop down icon" className="dropdown-icon" /></h3>
            <ul className="dropdown-content">
              <li><button onClick={() => navigate('/rep-ingresar-jugador')}>Inscribir</button></li>
              <li><button onClick={() => navigate('/rep-lista-jugadores')}>Ver inscritos</button></li>
              <li><button onClick={() => navigate('/rep-solicitudes-enviadas')}>Solicitudes</button></li>
            </ul>
          </div>
        </nav>
        <div className="logout">
          <button onClick={handleLogout}>Cerrar Sesión</button>
        </div>
      </div>
    </div>
  );
};

export default RepPrincipal;
