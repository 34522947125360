import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import './SolicitudesPendientes.css';

const SolicitudesPendientes = ({ solicitudes, handleViewFicha }) => {
  return (
    <table className="solicitudes-table">
      <thead>
        <tr>
          <th>Cédula</th>
          <th>Nombres</th>
          <th>Apellidos</th>
          <th>Estado</th>
          <th>Observación</th>
          <th>Fecha</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {solicitudes.map((solicitud, index) => (
          <tr key={index}>
            <td>{solicitud.cedula}</td>
            <td>{solicitud.nombres}</td>
            <td>{solicitud.apellidos}</td>
            <td>{solicitud.estado}</td>
            <td>{solicitud.observacion}</td>
            <td>{format(new Date(solicitud.updatedAt), 'dd/MM/yyyy')}</td>
            <td>
              {solicitud.estado === 'Negado' && (
                <button className="ver-ficha-btn" onClick={() => handleViewFicha(solicitud)}>Ver ficha</button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

SolicitudesPendientes.propTypes = {
  solicitudes: PropTypes.array.isRequired,
  handleViewFicha: PropTypes.func.isRequired,
};

export default SolicitudesPendientes;