import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Inicio from './pages/Inicio';
import NotFound from "./pages/NotFound";

// Importa las páginas administrativas
import AdminLogIn from './pages/admin/AdminLogIn/AdminLogIn';
import AdminPrincipal from './pages/admin/AdminPrincipal/AdminPrincipal';
import VerSolicitudes from './pages/admin/AdminVerSolicitudes/AdminVerSolicitudes';
import VerJugadores from './pages/admin/AdminVerJugadores/AdminVerJugadores';
import Inscribir from './pages/admin/AdminInscribir/AdminInscribir';
import VerInscritos from './pages/admin/AdminVerInscritos/AdminVerInscritos';
import AsignarRol from './pages/admin/AdminAsignarRol/AdminAsignarRol';
import VerClubes from './pages/admin/AdminVerClubes/AdminVerClubes';
import VerDocumentos from './pages/admin/AdminVerDocumentos/AdminVerDocumentos';
import VerRoles from './pages/admin/AdminVerRoles/AdminVerRoles';
import VerFicha from './pages/admin/AdminVerFicha/AdminVerFicha';
import RecuperarContrasena from './pages/admin/AdminRecuperarContrasena/AdminRecuperarContrasena';

// Importa las páginas de representante
import RepLogIn from './pages/rep/RepLogIn/RepLogIn';
import RepPrincipal from './pages/rep/RepPrincipal/RepPrincipal';
import RepCambioPass from './pages/rep/RepCambioPass/RepCambioPass';
import RepIngresarJugador from './pages/rep/RepIngresarJugador/RepIngresarJugador';
import RepActualizarJugador from './pages/rep/RepActualizarJugador/RepActualizarJugador';
import RepListaJugadores from './pages/rep/RepListaJugadores/RepListaJugadores';
import RepSolicitudesEnviadas from './pages/rep/RepSolicitudesEnviadas/RepSolicitudesEnviadas';
import RepFichaInscrito from './pages/rep/RepFichaInscrito/RepFichaInscrito';
import RepVerDocumentos from './pages/rep/RepVerDocumentos/RepVerDocumentos';


import { auth, firestore } from "./firebase/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

import ProtectedRoute from './components/ProtectedRoute';
import HandleRootRedirect from './components/HandleRootRedirect';

const App = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);
  const [loading, setLoading] = useState(true);

  async function getRol(uid) {
    try {
      const docuRef = doc(firestore, `usuarios/${uid}`);
      const docuCifrada = await getDoc(docuRef);
      const data = docuCifrada.data();
      return { rol: data.rol, nombre: data.nombre, passwordChanged: data.passwordChanged };
    } catch (error) {
      console.error("Error al obtener el rol del usuario:", error);
      return { rol: null, nombre: null, passwordChanged: false };
    }
  }

  function setUserWithFirebaseAndRol(usuarioFirebase) {
    getRol(usuarioFirebase.uid).then((info) => {
      const userData = {
        uid: usuarioFirebase.uid,
        email: usuarioFirebase.email,
        rol: info.rol,
        nombre: info.nombre,
        passwordChanged: info.passwordChanged
      };

      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (usuarioFirebase) => {
      if (usuarioFirebase) {
        await setUserWithFirebaseAndRol(usuarioFirebase);
      } else {
        setUser(null);
        localStorage.removeItem('user');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <HandleRootRedirect user={user} />
      <div className="App">
        <Routes>
          <Route path="/" element={<Inicio />} />

          {/* Ruta para el inicio de sesión de administradores */}
          <Route path="/2837c4b24crm0x9847xn283cd8cj0932r7nc238rcf34c" element={<AdminLogIn />} />

          {/* Ruta para el inicio de sesión de representantes */}
          <Route path="/rep-login" element={<RepLogIn />} />

          {/* Rutas para administradores */}
          <Route path="/admin-principal" element={<ProtectedRoute user={user} allowedRoles={['admin']}><AdminPrincipal /></ProtectedRoute>} />
          <Route path="/admin-ver-solicitudes" element={<ProtectedRoute user={user} allowedRoles={['admin']}><VerSolicitudes /></ProtectedRoute>} />
          <Route path="/admin-ver-jugadores" element={<ProtectedRoute user={user} allowedRoles={['admin']}><VerJugadores /></ProtectedRoute>} />
          <Route path="/admin-inscribir" element={<ProtectedRoute user={user} allowedRoles={['admin']}><Inscribir /></ProtectedRoute>} />
          <Route path="/admin-ver-inscritos" element={<ProtectedRoute user={user} allowedRoles={['admin']}><VerInscritos /></ProtectedRoute>} />
          <Route path="/admin-asignar-rol" element={<ProtectedRoute user={user} allowedRoles={['admin']}><AsignarRol /></ProtectedRoute>} />
          <Route path="/admin-ver-clubes" element={<ProtectedRoute user={user} allowedRoles={['admin']}><VerClubes /></ProtectedRoute>} />
          <Route path="/admin-ver-documentos" element={<ProtectedRoute user={user} allowedRoles={['admin']}><VerDocumentos /></ProtectedRoute>} />
          <Route path="/admin-ver-asignados" element={<ProtectedRoute user={user} allowedRoles={['admin']}><VerRoles /></ProtectedRoute>} />
          <Route path="/admin-ver-ficha" element={<ProtectedRoute user={user} allowedRoles={['admin']}><VerFicha /></ProtectedRoute>} />
          <Route path="/admin-recuperar-contrasena" element={<ProtectedRoute user={user} allowedRoles={['admin']}><RecuperarContrasena /></ProtectedRoute>} />

          {/* Rutas para representantes */}
          <Route path="/rep-principal" element={<ProtectedRoute user={user} allowedRoles={['rep']}><RepPrincipal /></ProtectedRoute>} />
          <Route path="/rep-cambio-pass" element={<ProtectedRoute user={user} allowedRoles={['rep']}><RepCambioPass /></ProtectedRoute>} />
          <Route path="/rep-ingresar-jugador" element={<ProtectedRoute user={user} allowedRoles={['rep']}><RepIngresarJugador /></ProtectedRoute>} />
          <Route path="/rep-actualizar-jugador" element={<ProtectedRoute user={user} allowedRoles={['rep']}><RepActualizarJugador /></ProtectedRoute>} />
          <Route path="/rep-lista-jugadores" element={<ProtectedRoute user={user} allowedRoles={['rep']}><RepListaJugadores /></ProtectedRoute>} />
          <Route path="/rep-solicitudes-enviadas" element={<ProtectedRoute user={user} allowedRoles={['rep']}><RepSolicitudesEnviadas /></ProtectedRoute>} />
          <Route path="/rep-ficha-inscrito" element={<ProtectedRoute user={user} allowedRoles={['rep']}><RepFichaInscrito /></ProtectedRoute>} />
          <Route path="/rep-ver-documentos" element={<ProtectedRoute user={user} allowedRoles={['rep']}><RepVerDocumentos /></ProtectedRoute>} />


          {/* Ruta de navegación si no hay usuario autenticado
          <Route path="*" element={<Navigate to={user ? (user.rol === 'admin' ? "/admin-principal" : "/rep-principal") : "/"} />} /> */}
          <Route path="*" element={<NotFound />} />

        </Routes>
      </div>
    </Router>
  );
};

export default App;