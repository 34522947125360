import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { format } from 'date-fns';
import './Solicitudes.css';
import useAuthToken from '../../hooks/useAuthToken';


const Solicitudes = ({ solicitudes, handleViewFicha }) => {
  const [clubNombres, setClubNombres] = useState({});
  const [sortedSolicitudes, setSortedSolicitudes] = useState([]);
  const { token } = useAuthToken();

  // Determinar la URL base de la API según el entorno
  const apiUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_PROD;

  useEffect(() => {
    // Ordenar solicitudes por fecha (de más antigua a más reciente)
    const sorted = [...solicitudes].sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    setSortedSolicitudes(sorted);
  }, [solicitudes]);

  useEffect(() => {
    const fetchClubNames = async () => {
      try {
        // Obtener nombres de clubes para cada solicitud
        const clubIds = Array.from(new Set(sortedSolicitudes.map(solicitud => solicitud.club_id))); // Eliminar duplicados

        const promises = clubIds.map(async clubId => {
          const response = await axios.get(`${apiUrl}/clubes/${clubId}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          return { clubId, clubNombre: response.data.club_nombre };
        });

        const clubNames = await Promise.all(promises);
        const clubNamesMap = clubNames.reduce((acc, curr) => {
          acc[curr.clubId] = curr.clubNombre;
          return acc;
        }, {});

        setClubNombres(clubNamesMap);
      } catch (error) {
        console.error('Error fetching club names:', error);
      }
    };
    if (token) {
      fetchClubNames();
    }
  }, [sortedSolicitudes, apiUrl, token]);

  return (
    <table className="solicitudes-table">
      <thead>
        <tr>
          <th>Club</th>
          <th>Nombres</th>
          <th>Apellidos</th>
          <th>Fecha</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {sortedSolicitudes.map((solicitud, index) => (
          <tr key={index}>
            <td>{clubNombres[solicitud.club_id] || 'Cargando...'}</td>
            <td>{solicitud.nombres}</td>
            <td>{solicitud.apellidos}</td>
            <td>{format(new Date(solicitud.updatedAt), 'dd/MM/yyyy')}</td>
            <td>
              <button className="ver-ficha-btn" onClick={() => handleViewFicha(solicitud.jugador_id)}>
                Ver Ficha
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Solicitudes.propTypes = {
  solicitudes: PropTypes.arrayOf(
    PropTypes.shape({
      club_id: PropTypes.number.isRequired,
      nombres: PropTypes.string.isRequired,
      apellidos: PropTypes.string.isRequired,
      estado: PropTypes.string.isRequired, // Aunque no es utilizado aquí, lo dejamos como está para futuras referencias
      jugador_id: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired
    })
  ).isRequired,
  handleViewFicha: PropTypes.func.isRequired
};

export default Solicitudes;
