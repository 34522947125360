import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/Header";
import Button from "../../../components/Button/Button";
import { auth } from "../../../firebase/firebase";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import "./RepCambioPass.css";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleUpdatePassword = async (event) => {
    event.preventDefault();
    setError("");
    setSuccess("");

    if (newPassword !== confirmPassword) {
      setError("Las contraseñas nuevas no coinciden.");
      return;
    }

    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, currentPassword);

      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);

      // Actualizar passwordChanged en Firestore
      const userDocRef = doc(firestore, 'usuarios', user.uid);
      await updateDoc(userDocRef, {
        passwordChanged: true
      });

      setSuccess("Contraseña actualizada correctamente.");
      alert('La contraseña fue actualizada correctamente');
      navigate('/rep-principal');

    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setError("La contraseña actual es incorrecta.");
      } else if (error.code === 'auth/weak-password') {
        setError("La nueva contraseña es demasiado débil.");
      } else {
        setError("Error al actualizar la contraseña. Por favor, intente de nuevo.");
      }
    }
  };

  return (
    <div className="UpdatePassword">
      <Header />
      <h2>Actualizar contraseña</h2>
      <main className="UpdatePassword-main">
        <form className="update-password-form" onSubmit={handleUpdatePassword}>
          <div className="form-group">
            <label htmlFor="currentPassword">Actual:</label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">Nueva:</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirmar:</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}
          <Button className="button" type="submit">Actualizar</Button>
        </form>
      </main>
    </div>
  );
};

export default UpdatePassword;