import React from 'react';
import PropTypes from 'prop-types';
import './PlayersList.css';

const PlayersList = ({ jugadores, handleViewFicha }) => {

  return (
    <div>
      <table className="jugadores-table">
        <thead>
          <tr>
            <th>Cédula</th>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>Edad</th>
            <th>Ciudad</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {jugadores.map((jugador, index) => (
            <tr key={index}>
              <td>{jugador.cedula}</td>
              <td>{jugador.nombres}</td>
              <td>{jugador.apellidos}</td>
              <td>{jugador.edad}</td>
              <td>{jugador.ciudad}</td>
              <td><button className="ver-ficha-btn" onClick={() => handleViewFicha(jugador.jugador_id)}>Ver Ficha</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

PlayersList.propTypes = {
  jugadores: PropTypes.arrayOf(
    PropTypes.shape({
      cedula: PropTypes.string.isRequired,
      nombres: PropTypes.string.isRequired,
      apellidos: PropTypes.string.isRequired,
      edad: PropTypes.number.isRequired,
      jugador_id: PropTypes.string.isRequired,
      ciudad: PropTypes.string.isRequired
    })
  ).isRequired,
  handleViewFicha: PropTypes.func.isRequired
};

export default PlayersList;