import React, { useState } from 'react';
import './Modal.css'; // Asegúrate de tener los estilos del modal

const Modal = ({ isOpen, onClose, onSubmit }) => {
  const [observacion, setObservacion] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    onSubmit(observacion); // Enviar observación al padre para su procesamiento
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
          <form onSubmit={handleSubmit}>
            <textarea
                value={observacion}
                onChange={(e) => setObservacion(e.target.value)}
                placeholder="Ingrese la observación..."
                required
            />
            <div className="modal-buttons">
                <button type="submit">Guardar</button>
                <button onClick={onClose}>Cancelar</button>
            </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;