import React from 'react';
import PropTypes from 'prop-types';
import './TitleContainer.css';

const TitleContainer = ({ text, onBackClick }) => {
  return (
    <div className="clubes-header">
      <button onClick={onBackClick} className="back-button">
        <img src={require('../../assets/icons/back.png')} alt="Back" />
      </button>
      <h2 className="centered-title">{text}</h2>
    </div>
  );
};

TitleContainer.propTypes = {
  text: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

export default TitleContainer;