import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import GreyButton from '../../../components/GreyButton/GreyButton';
import provinciasData from '../../../data/provincias.json';
import useAuthToken from '../../../hooks/useAuthToken';

import './AdminInscribir.css';

const AdminInscribir = () => {
  const { token } = useAuthToken();
  const [clubInfo, setClubInfo] = useState({
    club_nombre: '',
    ruc: '',
    presidente: '',
    delegado: '',
    provincia: '',
    ciudad: '',
    telefono: '',
    correo: '',
    estado: '',
    registro_de_directorio: null,
    acuerdo_ministerial: null,
    logo_club: null
  });

  // Determinar la URL base de la API según el entorno
  const apiUrl = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_PROD;

  const registroRef = useRef(null);
  const acuerdoRef = useRef(null);
  const logoRef = useRef(null);


  const [provincias, setProvincias] = useState(Object.keys(provinciasData));
  const [cantones, setCantones] = useState([]);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClubInfo({ ...clubInfo, [name]: value });

    if (name === 'provincia') {
      const selectedProvince = provinciasData[value] || {};
      setCantones(selectedProvince);
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setClubInfo({ ...clubInfo, [name]: files[0] });
    }
  };

  const handleBackClick = () => {
    navigate('/admin-principal');
  };

  const handleSave = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('club_nombre', clubInfo.club_nombre);
      formData.append('ruc', clubInfo.ruc);
      formData.append('presidente', clubInfo.presidente);
      formData.append('delegado', clubInfo.delegado);
      formData.append('provincia', clubInfo.provincia);
      formData.append('ciudad', clubInfo.ciudad);
      formData.append('telefono', clubInfo.telefono);
      formData.append('correo', clubInfo.correo);
      formData.append('estado', clubInfo.estado);

      if (registroRef.current.files[0]) {
      formData.append('registro_de_directorio', registroRef.current.files[0]);
    }
    if (acuerdoRef.current.files[0]) {
      formData.append('acuerdo_ministerial', acuerdoRef.current.files[0]);
    }
    if (logoRef.current.files[0]) {
      formData.append('logo_club', logoRef.current.files[0]);
    }

      console.log('Datos a enviar:', clubInfo);

      //PRODUCCION
      await axios.post(`${apiUrl}/clubes/create`, formData, {
        headers: {
          //'Content-Type': 'application/json; charset=utf-8',
          'Content-Type': 'multipart/form-data', // Configuración correcta para datos de formulario
          'Authorization': `Bearer ${token}`
        },
      });

      alert('Club inscrito satisfactoriamente');

      // Vaciar los campos del formulario
      setClubInfo({
        club_nombre: '',
        ruc: '',
        presidente: '',
        delegado: '',
        provincia: '',
        ciudad: '',
        telefono: '',
        correo: '',
        estado: '',
        registro_de_directorio: null,
        acuerdo_ministerial: null,
        logo_club: null
      });

      // Vaciar los campos de archivos
      if (registroRef.current) registroRef.current.value = null;
      if (acuerdoRef.current) acuerdoRef.current.value = null;
      if (logoRef.current) logoRef.current.value = null;

      navigate('/admin-inscribir');
    } catch (error) {
      if (error.response) {
        console.error('Datos del error:', error.response.data);
        console.error('Estado del error:', error.response.status);
        console.error('Encabezados del error:', error.response.headers);
    } else if (error.request) {
        console.error('No se recibió respuesta del servidor:', error.request);
      } else {
        console.error('Error al procesar la solicitud:', error.message);
      }
      alert('Club no ha podido ser inscrito');
    }
  };
  

  return (
    <div className="AdminInscribir">
      <Header />
      <main className="AdminInscribir-main">
        <Field text="Clubes" />
        <TitleContainer text="Registro de club" onBackClick={handleBackClick} />
        <form className="inscribir-form" onSubmit={handleSave}>
         
        <div className="section">
          <h3>DATOS DEL CLUB</h3>
          <div className="rep-form-group">
            <div className="form-item">
              <label htmlFor="club_nombre">Nombre del club:</label>
              <input
                type="text"
                id="club_nombre"
                name="club_nombre"
                value={clubInfo.club_nombre}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="ruc">RUC:</label>
              <input
                type="text"
                id="ruc"
                name="ruc"
                value={clubInfo.ruc}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="presidente">Presidente:</label>
              <input
                type="text"
                id="presidente"
                name="presidente"
                value={clubInfo.presidente}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="delegado">Delegado:</label>
              <input
                type="text"
                id="delegado"
                name="delegado"
                value={clubInfo.delegado}
                onChange={handleInputChange}
              />
            </div>


            <div className="form-item">
          <label htmlFor="provincia">Provincia:</label>
          <select
            id="provincia"
            name="provincia"
            value={clubInfo.provincia}
            onChange={handleInputChange}
          >
            <option value="">Seleccione una provincia</option>
            {provincias.map((provincia) => (
              <option key={provincia} value={provincia}>
                {provincia}
              </option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <label htmlFor="ciudad">Ciudad:</label>
          <select
            id="ciudad"
            name="ciudad"
            value={clubInfo.ciudad}
            onChange={handleInputChange}
            disabled={!Object.keys(cantones).length}
          >
          <option value="">Seleccione una ciudad</option>
           {Object.entries(cantones).map(([codigo, canton]) => (
           <option key={codigo} value={canton}>
            {canton}
             </option>
            ))}
            </select>
        </div>


          </div>
          </div>



          <div className="section">
            <h3>CONTACTO</h3>
            <div className="rep-form-group">
              <div className="form-item">
                <label htmlFor="telefono">Teléfono:</label>
                <input
                  type="text"
                  id="telefono"
                  name="telefono"
                  value={clubInfo.telefono}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-item">
                <label htmlFor="correo">Correo:</label>
                <input
                  type="text"
                  id="correo"
                  name="correo"
                  value={clubInfo.correo}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-item">
                <label htmlFor="estado">Estado:</label>
                <select
                  id="estado"
                  name="estado"
                  value={clubInfo.estado}
                  onChange={handleInputChange}
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Afiliado">Afiliado</option>
                  <option value="No afiliado">No afiliado</option>
                </select>
              </div>
            </div>
          </div>
          
          <div className="section">
            <h3>DOCUMENTOS</h3>
            <div className="rep-form-group-doc">
              <div className="form-item-doc">
                <label htmlFor="registro_de_directorio">Registro de directorio:</label>
                <input
                  type="file"
                  id="registro_de_directorio"
                  name="registro_de_directorio"
                  ref={registroRef}
                  onChange={handleFileChange}
                />
              </div>
              </div>

              <div className="rep-form-group-doc">
              <div className="form-item-doc">
                <label htmlFor="acuerdo_ministerial">Acuerdo ministerial:</label>
                <input
                  type="file"
                  id="acuerdo_ministerial"
                  name="acuerdo_ministerial"
                  ref={acuerdoRef}
                  onChange={handleFileChange}
                />
              </div>
              </div>

              <div className="rep-form-group-doc">
              <div className="form-item-doc">
                <label htmlFor="logo_club">Logo:</label>
                <input
                  type="file"
                  id="logo_club"
                  name="logo_club"
                  ref={logoRef}
                  onChange={handleFileChange}
                />
              </div>
              </div>
            </div>
          <GreyButton children="Guardar" type="submit" />
        </form>
      </main>
    </div>
  );
};

export default AdminInscribir;