import React from 'react';
import './GreyButton.css';

const GreyButton = ({ className, onClick, children }) => {
  return (
    <button className={`grey-button ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default GreyButton;
