import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ user, allowedRoles, children }) => {
  const location = useLocation();

  // Verifica si el usuario está autenticado
  if (!user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  // Verifica si el rol del usuario está en la lista de roles permitidos
  if (allowedRoles && !allowedRoles.includes(user.rol)) {
    return <Navigate to="/not-authorized" state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;