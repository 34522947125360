import React from 'react';
import PropTypes from 'prop-types';
import './Documentos.css';

const Documentos = ({ documentos }) => {

  const handleViewDocument = (url) => {
    if (url) {
      window.open(url, '_blank'); // Abre el documento en una nueva pestaña
    } else {
      console.error('URL del documento no válida.');
    }
  };

  const handleDownloadDocument = (url) => {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.split('/').pop(); // Nombre del archivo basado en la URL
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('URL del documento no válida.');
    }
  };

  return (
    <div className="documentos-container">
      <table className="documentos-table">
        <thead>
          <tr>
            <th>Documento</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>

        <tr>
            <td>Foto</td>
            <td>
              {documentos.foto ? (
                <>
                  <button className="ver-documento-btn" onClick={() => handleViewDocument(documentos.foto)}>
                    Ver Documento
                  </button>
                </>
              ) : (
                <span>No disponible</span>
              )}
            </td>
          </tr>

          <tr>
            <td>Cédula</td>
            <td>
              {documentos.cedula ? (
                <>
                  <button className="ver-documento-btn" onClick={() => handleViewDocument(documentos.cedula)}>
                    Ver Documento
                  </button>
                </>
              ) : (
                <span>No disponible</span>
              )}
            </td>
          </tr>

          <tr>
            <td>Ficha Firmada</td>
            <td>
              {documentos.ficha_firmada ? (
                <>
                  <button className="ver-documento-btn" onClick={() => handleViewDocument(documentos.ficha_firmada)}>
                    Ver Documento
                  </button>
                </>
              ) : (
                <span>No disponible</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Ficha Médica</td>
            <td>
              {documentos.ficha_medica ? (
                <>
                  <button className="ver-documento-btn" onClick={() => handleViewDocument(documentos.ficha_medica)}>
                    Ver Documento
                  </button> 
                </>
              ) : (
                <span>No disponible</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Autorización Representante</td>
            <td>
              {documentos.autorizacion_representante ? (
                <>
                  <button className="ver-documento-btn" onClick={() => handleViewDocument(documentos.autorizacion_representante)}>
                    Ver Documento
                  </button>
                </>
              ) : (
                <span>No disponible</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

Documentos.propTypes = {
  documentos: PropTypes.shape({
    ficha_firmada: PropTypes.string,
    ficha_medica: PropTypes.string,
    autorizacion_representante: PropTypes.string,
  }).isRequired,
};

export default Documentos;