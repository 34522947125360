import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import FichaInscrito from '../../../components/FichaInscrito/FichaInscrito';
import Modal from '../../../components/Modal/Modal'; // Importa tu modal o componente para ingresar texto
import './RepFichaInscrito.css';
import axios from 'axios';
import useAuthToken from '../../../hooks/useAuthToken';

const RepFichaInscrito = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { jugador_id } = location.state || {};
  const { token } = useAuthToken();

   // Determinar la URL base de la API según el entorno
   const apiUrl = process.env.NODE_ENV === 'production' 
   ? process.env.REACT_APP_API_URL_PROD 
     : process.env.REACT_APP_API_URL_PROD;


  const [jugador, setJugador] = useState({
    jugador_id: '',
    nombres: '',
    apellidos: '',
    cedula: '',
    fecha_de_nacimiento: '',
    nacionalidad: '',
    edad: '',
    sexo: '',
    tipo_de_sangre: '',
    pais: '',
    provincia: '',
    ciudad: '',
    direccion: '',
    telefono: '',
    observacion: '' // Agrega el campo de observacion en el estado del jugador
  });


  useEffect(() => {
    const fetchJugador = async () => {
      try {
        const response = await axios.get(`${apiUrl}/jugadores/${jugador_id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const jugadorEncontrado = response.data;
        if (jugadorEncontrado) {
          setJugador(jugadorEncontrado);
        }
      } catch (error) {
        console.error('Error fetching player data:', error);
      }
    };
    if (token) {
    if (jugador_id) {
      fetchJugador();
    }
  }
  }, [jugador_id, token]);
  

  const handleBackClick = () => {
    navigate('/rep-lista-jugadores');
  };

  const handleViewDocuments = () => {
    navigate('/rep-ver-documentos', { state: { jugador_id } });
  };


  return (
    <div className="admin-ver-ficha">
      <Header />
      <Field text="Jugadores" />
      <TitleContainer text="Ficha del Jugador" onBackClick={handleBackClick} />
      <main className="rep-ver-ficha-main">
        <FichaInscrito 
          jugador={jugador}
          handleViewDocuments={handleViewDocuments}
        />
      </main>
      </div>
  );
};

export default RepFichaInscrito;