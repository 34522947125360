import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/Header";
import Button from "../../../components/Button/Button";
import { auth } from "../../../firebase/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import "./AdminRecuperarContrasena.css";

const RecuperarPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleBackClick = () => {
    navigate('/admin-principal');
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setError("");
    setSuccess("");

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess("Correo de recuperación enviado correctamente.");
    } catch (error) {
      if (error.code === 'auth/invalid-email') {
        setError("El correo electrónico no es válido.");
      } else if (error.code === 'auth/user-not-found') {
        setError("No se encontró un usuario con ese correo electrónico.");
      } else {
        setError("Error al enviar el correo de recuperación. Por favor, intente de nuevo.");
      }
    }
  };

  return (
    <div className="RecuperarPassword">
      <Header />
      <TitleContainer text="Recuperar contraseña" onBackClick={handleBackClick} />
      <main className="RecuperarPassword-main">
        <form className="recuperar-password-form" onSubmit={handlePasswordReset}>
          <div className="form-group">
            <label htmlFor="email">Correo Electrónico:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="correo@ejemplo.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}
          <Button className="button" type="submit">Enviar</Button>
        </form>
      </main>
    </div>
  );
};

export default RecuperarPassword;
