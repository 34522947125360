// src/pages/NotFound.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebase"; // Asegúrate de tener la ruta correcta para tu archivo firebase

const NotFound = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    // Cierra sesión al cargar la página
    const logout = async () => {
      try {
        await signOut(auth);
        navigate("/"); // Redirige a la página de inicio después de cerrar sesión
      } catch (error) {
        console.error("Error al cerrar sesión:", error);
      }
    };

    logout();
  }, [navigate]);

  return (
    <div>
      <h1>Página no encontrada</h1>
      <p>La página que estás buscando no existe.</p>
    </div>
  );
};

export default NotFound;