import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../../../firebase/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import Roles from '../../../components/Roles/Roles';
import './AdminVerRoles.css';

const AdminVerRoles = () => {
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const q = query(collection(firestore, 'usuarios'), where('rol', '==', 'rep'));
        const querySnapshot = await getDocs(q);
        const rolesData = querySnapshot.docs.map((doc) => doc.data());
        setRoles(rolesData);
      } catch (error) {
        console.error('Error al obtener roles:', error);
      }
    };

    fetchRoles();
  }, []);

  const handleBackClick = () => {
    navigate('/admin-principal');
  };

  return (
    <div className="AdminVerRoles">
      <Header />
      <Field text="Roles" />
      <TitleContainer text="Ver Roles Asignados" onBackClick={handleBackClick} />

      {/* Renderiza el componente Roles con los datos obtenidos */}
      <Roles roles={roles} />
    </div>
  );
};

export default AdminVerRoles;