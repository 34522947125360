import React from 'react';
import PropTypes from 'prop-types';
import Club from '../Club/Club';
import './ClubList.css';

const ClubList = ({ clubs, handleClubClick }) => {
  return (
    <div className="club-list">
      {clubs.map((club, index) => (
        <Club
          key={index}
          name={club.club_nombre}
          logo={club.logo_club} // Pasa el logo del club aquí
          handleClubClick={handleClubClick}
        />
      ))}
    </div>
  );
};

ClubList.propTypes = {
  clubs: PropTypes.arrayOf(
    PropTypes.shape({
      club_nombre: PropTypes.string.isRequired,
      logo_club: PropTypes.string, // Añadir el logo_club al tipo
    })
  ).isRequired,
  handleClubClick: PropTypes.func.isRequired,
};

export default ClubList;